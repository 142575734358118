import Layout from "./Layout";
import { useEffect } from "react";

const HIW = () => {
    useEffect(()=>{
        document.querySelector("video").play();
    },[])
  return (
    <Layout>
      <video autoPlay loop muted controls className="vid-hitworks">
        <source src="https://api.google4share.com/assets/how-it-works.mp4" type="video/mp4" />
      </video>
    </Layout>
  );
};
export default HIW;
