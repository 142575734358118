import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Layout = (props) => {
  const [cActive, setcActive] = useState(true);
  const ArrayText = ["File's", "Zip", "Image's", "Video's", "Text"];
  const [cText, setcText] = useState(ArrayText[0]);
  const ArratImage = [
    "https://api.google4share.com/assets/1.png",
    "https://api.google4share.com/assets/2.png",
    "https://api.google4share.com/assets/3.png",
    "https://api.google4share.com/assets/man.gif",
  ];
  Array.prototype.random = function () {
    return this[Math.floor(Math.random() * this.length)];
  };
  useEffect(() => {
    var c = 0;
    setInterval(() => {
      c = c + 1;
      if (c >= ArrayText.length) {
        c = 0;
      }
      setcActive(false);
    }, 3000);
    setInterval(() => {
      setcActive(true);
      setcText(`${ArrayText[c]}`);
    }, 4000);
    var node1 = document.querySelector(".line1");
    var node2 = document.querySelector(".line2");
    var node3 = document.querySelector(".line3");
    var isOpen = false;
    document.getElementById("navtoggler").onclick = () => {
      if (isOpen) {
        isOpen = false;
        document.querySelector(".mob-navbar").style.transform =
          "translateX(100%)";
        document.querySelector("body").classList.remove("remove-scroll");
      } else {
        isOpen = true;
        document.querySelector(".mob-navbar").style.transform =
          "translateX(0px)";
        document.querySelector("body").classList.add("remove-scroll");
      }
      var style =
        node1.classList.contains("line1-change") === true ? "hide" : "show";
      if (style === "hide") {
        node1.classList.remove("line1-change");
        node2.classList.remove("line2-change");
        node3.classList.remove("line3-change");
      } else {
        node1.classList.add("line1-change");
        node2.classList.add("line2-change");
        node3.classList.add("line3-change");
      }
    };
    document.documentElement.style.setProperty(
      "--image-upload-card",
      "url(" + ArratImage.random() + ")"
    );
  }, []);
  return (
    <div className="App">
      <header>
        <div>Virtual Desktop for your Wi-Fi | Google4Share</div>
        <div className="navbar_nmob mob-navbar">
          <ul className="nav_ul">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/how-it-works">How it works</Link>
            </li>
            <li>
              <Link to="/reviews">Reviews</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        <button
          className="pasa_navbnt"
          id="navtoggler"
          name="NavBar-Button"
          aria-label="Open Navigation Bar"
        >
          <div className="mt-21">
            <span className="line1">&nbsp;</span>
            <span className="line2">&nbsp;</span>
            <span className="line3">&nbsp;</span>
          </div>
        </button>
      </header>
      {props?.onlyHeader === true ? (
        props.children
      ) : (
        <>
          <div className="ssbf">
            <h1>
              Send Super Big{" "}
              <span className={`${cActive === true ? "active" : ""}`}>
                {cText}
              </span>
            </h1>
            <h2>SIMPLE, FAST, BEAUTIFUL</h2>
          </div>
          <div className="uload_card">
            <svg
              fill="none"
              className="svg_stroke"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                rx="44.5"
                stroke="white"
                strokeWidth="3"
                strokeDasharray="19 19"
              />
            </svg>
            {props.children}
          </div>
        </>
      )}
    </div>
  );
};
export default Layout;
