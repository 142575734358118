import axios from "axios";

const LocationChange = (setcCountry) => {
  axios
    .get(
      "https://api.google4share.com/location.php",
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(({ data }) => {
      setcCountry(data?.ccode);
      localStorage.setItem("ccode", data?.ccode);
    })
    .catch(() => {
      console.log("Failed To Get Location Check Your Connection");
      window.addEventListener("online", () => {
        LocationChange(setcCountry);
      });
    });
};
export default LocationChange;
