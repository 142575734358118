import Layout from "./Layout";
import Files from "../components/Files";
function File() {
  return (
    <Layout>
      <Files />
    </Layout>
  );
}

export default File;
