import { Link } from "react-router-dom";

const Main = () => {
  return (
    <div className="main_cent">
      <div className="ucard_bg">
        <svg
          fill="none"
          className="svg_stroke"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            rx="44.5"
            stroke="#8B8B8B"
            strokeWidth="3"
            strokeDasharray="19 19"
          />
        </svg>
        <Link to="/share-files" className="add_ntb">
          <button
            className="add_ntb"
            aria-label="Share File"
            title="Share File"
          >
            <svg
              width="17"
              height="17"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <rect width="22" height="22" fill="url(#pattern0)" />
              <defs>
                <pattern
                  id="pattern0"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use xlinkHref="#image0_11_9" transform="scale(0.0107527)" />
                </pattern>
                <image
                  id="image0_11_9"
                  width="93"
                  height="93"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAF0AAABdCAYAAADHcWrDAAAAAXNSR0IArs4c6QAAAURJREFUeJzt27FNw1AYRtE/CEFFlS6zsANzMB5DMAQLpErHArRpUAo/6VjyPZULP+nzLVxY8mn27Twz7xvO32bme9GWZZ71gAdeZ+aiR6z2pAccUdGBogNFB4oOFB0oOlB0oOhA0YGiA0UHig4UHSg6UHSg6EDRgaIDRQeKDhQdKDpQdKDoQNGBogNFB4oOFB0oOlB0oOhA0YGiA0UH7n/02uMPVeeN519mh891urv+ZCsOptcLUHSg6EDRgaIDRQeKDhQdKDpQdKDoQNGBogP3n3Z/2Ir/vc22T7O/M3NdtGWZ0+NbqMvMfGw4f52Zr0Vblun1AhQdKDpQdKDoQNGBogNFB4oOFB0oOlB0oOhA0YGiA0UHig4UHSg6UHSg6EDRgaIDRQeKDhQdKDpQdKDoQNGBogNFB4oOFB0oOlB0oOg5hj9juAhsd2nzMAAAAABJRU5ErkJggg=="
                />
              </defs>
            </svg>
          </button>
        </Link>
        <div className="ayf ayf-sty">
          Add your{" "}
          <Link to="/share-files" className="link_hc">
            files
          </Link>{" "}
          or{" "}
          <Link to="/share-text" className="link_hc">
            {" "}
            text
          </Link>
        </div>
        <div className="upto">upto 20GB/10k words</div>
      </div>
    </div>
  );
};
export default Main;
