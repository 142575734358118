import Layout from "./Layout";
import Text from "../components/text";
function TextPage() {
  return (
    <Layout>
      <Text />
    </Layout>
  );
}

export default TextPage;
