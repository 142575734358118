import React from "react";
import Ripples from "react-ripples";
import { Link } from "react-router-dom";
import Location from "./Location";
import { useEffect, useState } from "react";
import Label from "./label";
import { useAutosave } from "react-autosave";
import axios from "axios"; 
import LocationChange from "./LocationChange";

const Text = () => {
  const [Tab, setTeb] = useState();
  const [Loading, setLoading] = useState(false);
  const [cCountry, setcCountry] = useState(localStorage.getItem("ccode"));
  const [cSelec, setcSelect] = useState(localStorage.getItem("sloc"));
  const [shareText, setShareText] = React.useState(
    Window.ServerData?.csharedtext
  );
  const uploadText = (data) => {
    setLoading(true);
    axios
      .post(
        "https://api.google4share.com/text.php",
        { text: data, cloc: localStorage.getItem("sloc") },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading("error");
      });
  };
  const updateText = () => {
    setLoading(true);
    axios
      .get(
        "https://api.google4share.com/datatext.php?cloc=" +
          localStorage.getItem("sloc"),
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => {
        setLoading(false);
        if (data?.success) {
          setShareText(data?.text);
        }
      })
      .catch(() => {
        setLoading("error");
      });
  };
  useAutosave({ data: shareText, onSave: uploadText });
  useEffect(() => {
    setTeb("text");
    updateText();
    LocationChange(setcCountry);
    if (window?.navigator?.connection) {
      window.navigator.connection.onchange = () => {
        LocationChange(setcCountry);
      };
    }
  }, []);
  return (
    <div>
      <Label setcSelect={setcSelect} cSelec={cSelec} />
      <div className="btns_flex_tp">
        <Ripples className="bbtn_top">
          <Link to="/">
            <button className="bbtn_top">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="25"
                viewBox="0 96 960 960"
                width="25"
              >
                <path d="M480 856 200 576l280-280 16 16-254 253h518v22H242l254 253-16 16Z" />
              </svg>
            </button>
          </Link>
        </Ripples>
        <div>
          {Loading === true ? (
            <Ripples className="bbtn_top mr-5">
              <button className="bbtn_top" title="Sharing ...">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  className="rotate_svg"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M196 862v-22h134l-50-49q-48-47-71-99.5T186 580q0-90 50-165t134-111v23q-74 34-118 102.5T208 580q0 56 21 104t62 87l56 54V689h22v173H196Zm394-14v-23q75-34 118.5-102.5T752 572q0-51-21-100.5T670 382l-56-55v136h-22V290h173v22H631l49 50q48 47 71 101.5T774 572q0 90-50 165T590 848Z" />
                </svg>
              </button>
            </Ripples>
          ) : Loading === "error" ? (
            <Ripples
              className="bbtn_top mr-5"
              onClick={() => {
                uploadText(shareText);
              }}
            >
              <button
                className="bbtn_top error_ntb"
                title="Error While Sharing Check Your Internet Connection"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M479.982 748q8.518 0 13.268-4.732 4.75-4.733 4.75-13.25 0-8.518-4.732-13.268-4.733-4.75-13.25-4.75-8.518 0-13.268 4.732-4.75 4.733-4.75 13.25 0 8.518 4.732 13.268 4.733 4.75 13.25 4.75ZM470 626h22V382h-22v244Zm10.44 298q-72.908 0-135.732-27.391-62.825-27.392-110.341-74.848-47.515-47.457-74.941-110.276Q132 648.667 132 575.674q0-71.993 27.391-135.392 27.392-63.399 74.848-110.414 47.457-47.016 110.276-74.442Q407.333 228 480.326 228q71.993 0 135.392 27.391 63.399 27.392 110.414 74.348 47.016 46.957 74.442 110.435Q828 503.652 828 575.56q0 72.908-27.391 135.732-27.392 62.825-74.348 110.156-46.957 47.332-110.435 74.942Q552.348 924 480.44 924Zm.06-22Q616 902 711 807t95-231.5Q806 440 711.188 345 616.375 250 480 250q-136 0-231 94.812Q154 439.625 154 576q0 136 95 231t231.5 95Zm-.5-326Z" />
                </svg>
              </button>
            </Ripples>
          ) : (
            <Ripples className="bbtn_top mr-5">
              <button
                className="bbtn_top"
                title="Current Text Is Shared .
Click To Get The Latest Update."
                onClick={() => {
                  updateText();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M411 918q-61-14-112-45t-87.5-76Q175 752 155 695.5T135 576q0-84 39-158.5T293 285H159v-22h172v172h-22V302q-77 52-114.5 124T157 576q0 115 69 203.5T411 895v23Zm12-198L294 591l16-16 113 113 227-227 16 16-243 243Zm206 170V718h22v133q77-53 114.5-125T803 576q0-115-69-203.5T549 257v-23q123 27 199.5 121T825 576q0 84-39 159T667 868h134v22H629Z" />
                </svg>
              </button>
            </Ripples>
          )}
          {Tab === "text" ? (
            <Ripples
              className="bbtn_top"
              onClick={() => {
                setTeb("location");
              }}
            >
              <button className="bbtn_top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M521 922v-22q47-7 87.5-23.5T680 835l14 16q-37 30-80 46.5T521 922Zm234-131-16-15q26-34 42.5-74t22.5-85h22q-5 46-23.5 90T755 791Zm49-256q-6-45-22.5-85T739 376l17-14q33 47 46.5 82t23.5 91h-22ZM439 922q-131-17-218-115.5T134 576q0-133 87-231.5T439 230v22q-122 17-202.5 108.5T156 576q0 122 80.5 214T439 900v22Zm243-603q-37-27-77.5-44T524 252v-22q46 7 88 24.5t82 46.5l-12 18ZM480 728q-61-53-91.5-98.5T358 546q0-57 36.5-91t85.5-34q49 0 85.5 34t36.5 91q0 38-30.5 83.5T480 728Zm0-152q14 0 23.5-9.5T513 543q0-14-9.5-23.5T480 510q-14 0-23.5 9.5T447 543q0 14 9.5 23.5T480 576Z" />
                </svg>
              </button>
            </Ripples>
          ) : (
            <Ripples
              className="bbtn_top"
              onClick={() => {
                setTeb("text");
              }}
            >
              <button className="bbtn_top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="m254 818-16-16 226-226-226-226 16-16 226 226 226-226 16 16-226 226 226 226-16 16-226-226-226 226Z" />
                </svg>
              </button>
            </Ripples>
          )}
        </div>
      </div>
      {Tab === "text" ? (
        <textarea
          className="text_area_mond"
          placeholder={`Type Something To Share .....
We Auto Share You'r Text .
You Can Share Up To 10K Word's.
You Are Currently Sharing On ${
            cSelec === "swn" ? "Selected Wifi Network" : cSelec
          }`}
          value={shareText}
          onChange={(e) => setShareText(e.target.value)}
          autoFocus
        ></textarea>
      ) : (
        <Location
          setcSelect={setcSelect}
          cSelec={cSelec}
          cCountry={cCountry}
          update={updateText}
        />
      )}
    </div>
  );
};
export default Text;
