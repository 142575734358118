import { useState } from "react";
import Ripples from "react-ripples";
import { Link } from "react-router-dom";
import axios from "axios";

const Card = ({ v, setSharedFiles, index, sharedFiles }) => {
  var re = /(?:\.([^.]+))?$/;
  var CanPrevExt = ["png", "svg", "jpg", "jpeg", "gif"];
  const [Loading, setLoading] = useState(false);
  var ext = re.exec(v?.real_name)[1];
  const deleteFile = (data) => {
    setLoading(true);
    axios
      .get(
        `https://api.google4share.com/deletefile.php?name=${data}&cloc=` +
          localStorage.getItem("sloc"),
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        setLoading(false);
        sharedFiles.splice(index, 1);
        setSharedFiles([...sharedFiles]);
      })
      .catch(() => {
        setLoading("error");
      });
  };
  const [isEror, setisError] = useState(false);
  return (
    <>
      <div className="card-life-s">
        <div className="mig-life">
          {CanPrevExt.includes(ext) === true ? (
            isEror === true ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="48"
                viewBox="0 96 960 960"
                width="48"
              >
                <title>
                  Error While Loading File Check Console For More Information
                </title>
                <path d="M479.982 748q8.518 0 13.268-4.732 4.75-4.733 4.75-13.25 0-8.518-4.732-13.268-4.733-4.75-13.25-4.75-8.518 0-13.268 4.732-4.75 4.733-4.75 13.25 0 8.518 4.732 13.268 4.733 4.75 13.25 4.75ZM470 626h22V382h-22v244Zm10.44 298q-72.908 0-135.732-27.391-62.825-27.392-110.341-74.848-47.515-47.457-74.941-110.276Q132 648.667 132 575.674q0-71.993 27.391-135.392 27.392-63.399 74.848-110.414 47.457-47.016 110.276-74.442Q407.333 228 480.326 228q71.993 0 135.392 27.391 63.399 27.392 110.414 74.348 47.016 46.957 74.442 110.435Q828 503.652 828 575.56q0 72.908-27.391 135.732-27.392 62.825-74.348 110.156-46.957 47.332-110.435 74.942Q552.348 924 480.44 924Zm.06-22Q616 902 711 807t95-231.5Q806 440 711.188 345 616.375 250 480 250q-136 0-231 94.812Q154 439.625 154 576q0 136 95 231t231.5 95Zm-.5-326Z" />
              </svg>
            ) : (
              <img
                src={v?.download_url}
                alt=""
                onError={() => {
                  setisError(true);
                }}
              />
            )
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              viewBox="0 96 960 960"
              width="48"
            >
              <path d="M266 924q-22.775 0-38.387-15.612Q212 892.775 212 870V282q0-22.775 15.613-38.388Q243.225 228 266 228h314l168 168v474q0 22.775-15.612 38.388Q716.775 924 694 924H266Zm0-22h428q12 0 22-10t10-22V406H570V250H266q-12 0-22 10t-10 22v588q0 12 10 22t22 10Zm214.361-93Q529 809 561.5 772.375 594 735.75 594 686V502h-16v184q0 43-27.75 75T480 793q-42.225 0-70.112-32Q382 729 382 686V468q0-20.7 12.362-35.85Q406.724 417 427.207 417 447 417 459.5 432.15T472 468v212h16V468q0-26.48-17.577-46.74-17.578-20.26-43.5-20.26Q401 401 383.5 421.26 366 441.52 366 468v218q0 49.75 32.844 86.375Q431.687 809 480.361 809ZM234 250v156-156 652-652Z" />
            </svg>
          )}
        </div>
        <div className="lifeName" title={v?.view_name}>
          {v?.view_name}
        </div>
        <div className="btnlife">
          <a
            href={`https://api.google4share.com/download.php?nama=${v?.real_name}&view_name=${v?.view_name}`}
            rel="noopener noreferrer"
            target="_blank"
            download={v?.view_name}
          >
            <Ripples className="bbtn_top mr-5">
              <button className="bbtn_top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M266 844q-22.775 0-38.387-15.612Q212 812.775 212 790v-95h22v95q0 12 10 22t22 10h428q12 0 22-10t10-22v-95h22v95q0 22.775-15.612 38.388Q716.775 844 694 844H266Zm214-146L358 576l16-15 95 94V290h22v365l95-94 16 15-122 122Z" />
                </svg>
              </button>
            </Ripples>
          </a>

          {Loading === true ? (
            <Ripples className="bbtn_top mr-5">
              <button className="bbtn_top" title="Saving ...">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  className="rotate_svg"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M196 862v-22h134l-50-49q-48-47-71-99.5T186 580q0-90 50-165t134-111v23q-74 34-118 102.5T208 580q0 56 21 104t62 87l56 54V689h22v173H196Zm394-14v-23q75-34 118.5-102.5T752 572q0-51-21-100.5T670 382l-56-55v136h-22V290h173v22H631l49 50q48 47 71 101.5T774 572q0 90-50 165T590 848Z" />
                </svg>
              </button>
            </Ripples>
          ) : Loading === "error" ? (
            <Ripples
              className="bbtn_top mr-5"
              onClick={() => {
                deleteFile(v?.real_name);
              }}
            >
              <button
                className="bbtn_top error_ntb"
                title="Error While Deleting Check Your Internet Connection"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M479.982 748q8.518 0 13.268-4.732 4.75-4.733 4.75-13.25 0-8.518-4.732-13.268-4.733-4.75-13.25-4.75-8.518 0-13.268 4.732-4.75 4.733-4.75 13.25 0 8.518 4.732 13.268 4.733 4.75 13.25 4.75ZM470 626h22V382h-22v244Zm10.44 298q-72.908 0-135.732-27.391-62.825-27.392-110.341-74.848-47.515-47.457-74.941-110.276Q132 648.667 132 575.674q0-71.993 27.391-135.392 27.392-63.399 74.848-110.414 47.457-47.016 110.276-74.442Q407.333 228 480.326 228q71.993 0 135.392 27.391 63.399 27.392 110.414 74.348 47.016 46.957 74.442 110.435Q828 503.652 828 575.56q0 72.908-27.391 135.732-27.392 62.825-74.348 110.156-46.957 47.332-110.435 74.942Q552.348 924 480.44 924Zm.06-22Q616 902 711 807t95-231.5Q806 440 711.188 345 616.375 250 480 250q-136 0-231 94.812Q154 439.625 154 576q0 136 95 231t231.5 95Zm-.5-326Z" />
                </svg>
              </button>
            </Ripples>
          ) : (
            <Ripples
              className="bbtn_top mr-5"
              onClick={() => {
                deleteFile(v?.real_name);
              }}
            >
              <button className="bbtn_top" title="Delete File">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M306 884q-21.75 0-37.875-16.125T252 830V327h-40v-22h144v-25h248v25h144v22h-40v503q0 22.775-15.612 38.388Q676.775 884 654 884H306Zm380-557H274v503q0 14 9.5 23t22.5 9h348q12 0 22-10t10-22V327ZM404 780h22V408h-22v372Zm130 0h22V408h-22v372ZM274 327v535-535Z" />
                </svg>
              </button>
            </Ripples>
          )}

          <Link to={v?.download_url} target="_blank">
            <Ripples className="bbtn_top">
              <button className="bbtn_top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M180 872q-8.775 0-15.387-6.612Q158 858.775 158 850h22v22Zm-22-104v-81h22v81h-22Zm0-164v-81h22v81h-22Zm0-163v-81h22v81h-22Zm0-163q0-8.775 6.613-15.388Q171.225 256 180 256v22h-22Zm104 594v-22h81v22h-81Zm164 0v-22h81v22h-81Zm163 0v-22h81v22h-81Zm163 0v-22h22q0 9-6.612 15.5Q760.775 872 752 872Zm-444-96q-22.775 0-38.388-15.612Q254 744.775 254 722V282q0-22.775 15.612-38.388Q285.225 228 308 228h440q22.775 0 38.388 15.612Q802 259.225 802 282v440q0 22.775-15.612 38.388Q770.775 776 748 776H308Zm0-22h440q12 0 22-10t10-22V366H534V250H308q-12 0-22 10t-10 22v440q0 12 10 22t22 10Zm-32-504v504-504Z" />
                </svg>
              </button>
            </Ripples>
          </Link>
        </div>
      </div>
    </>
  );
};
export default Card;
