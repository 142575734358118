import React from "react";
import ReactDOM from "react-dom/client";

import "./App.css";
import App from "./pages/App";
import TextPage from "./pages/Text";
import File from "./pages/File";
import HowItWorks from "./pages/HIW";
import Contact from "./pages/contact";
import Reviews from "./pages/Reviews";
import UploadReviews from "./pages/UploadReview";
import PageNotFound from "./pages/PNF";

import { BrowserRouter, Routes, Route } from "react-router-dom";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}></Route>
      <Route path="/share-text" element={<TextPage />}></Route>
      <Route path="/create-review" element={<UploadReviews />}></Route>
      <Route path="/share-files" element={<File />}></Route>
      <Route path="/how-it-works" element={<HowItWorks />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/reviews" element={<Reviews />}></Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  </BrowserRouter>
);
