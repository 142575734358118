import { useEffect, useState } from "react";
import Layout from "./Layout";
import Ripples from "react-ripples";
import { Link } from "react-router-dom";
import axios from "axios";

function Reviews() {
  const [Reviews, setReviews] = useState([]);
  const [isEror, setisError] = useState(false);
  const GetReviews = () => {
    setisError("loading");
    axios
      .get(
        "https://api.google4share.com/get_reviews.php",
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(({ data }) => {
        setisError(false);
        setReviews(data?.data);
      })
      .catch(() => {
        setisError(true);
        console.log("Failed To Get Record Check Your Connection");
      });
  };
  const Card = ({ data }) => {
    const [isPlay, setisPlay] = useState(false);
    function Play() {
      if (isPlay) {
        setisPlay(false);
        document.getElementById("video_" + data?.key).pause();
      } else {
        setisPlay(true);
        document.getElementById("video_" + data?.key).play();
      }
    }
    return (
      <div className="video-box">
        <div className="paper-img-prod video-paper-xo">
          <video src={data?.url} id={"video_" + data?.key} muted={false}></video>
        </div>
        <h2 className="paper-prod-naem">{data?.name}</h2>
        <p className="paper-prod-para">{data?.email}</p>
        <div className="review-wraper">
          <Ripples className="play-btn-paper">
            <button
              className="play-btn-paper"
              onClick={() => {
                Play();
              }}
            >
              {isPlay === false ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                  fill="#fff"
                >
                  <path d="m410 704 200-129-200-128v257ZM186 844q-22.775 0-38.387-15.612Q132 812.775 132 790V362q0-22.775 15.613-38.388Q163.225 308 186 308h588q22.775 0 38.388 15.612Q828 339.225 828 362v428q0 22.775-15.612 38.388Q796.775 844 774 844H186Zm0-22h588q12 0 22-10t10-22V362q0-12-10-22t-22-10H186q-12 0-22 10t-10 22v428q0 12 10 22t22 10Zm-32 0V330v492Z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                  fill="#fff"
                >
                  <path d="M548 804V348h160v456H548Zm-296 0V348h161v456H252Zm318-22h116V370H570v412Zm-296 0h117V370H274v412Zm0-412v412-412Zm296 0v412-412Z" />
                </svg>
              )}
            </button>
          </Ripples>
        </div>
      </div>
    );
  };
  useEffect(() => {
    GetReviews();
  }, []);
  return (
    <Layout onlyHeader={true}>
      <div className="ssbf">
        <h1>Our Reviews</h1>
        <h2>Customer testimonials speak for our excellence.</h2>
        <div className="d-flex justify-content-right">
          <Ripples className="bbtn_top bbtn_long mr-5 pl-0 pr-0">
            <Link to={`/create-review`}>
              <button
                className="bbtn_top bbtn_long"
                title="Create Your Review ?"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M411 918q-61-14-112-45t-87.5-76Q175 752 155 695.5T135 576q0-84 39-158.5T293 285H159v-22h172v172h-22V302q-77 52-114.5 124T157 576q0 115 69 203.5T411 895v23Zm12-198L294 591l16-16 113 113 227-227 16 16-243 243Zm206 170V718h22v133q77-53 114.5-125T803 576q0-115-69-203.5T549 257v-23q123 27 199.5 121T825 576q0 84-39 159T667 868h134v22H629Z" />
                </svg>
                Upload Review
              </button>
            </Link>
          </Ripples>
        </div>
        <div className="flex-reviews">
          {Reviews.map((v, i) => {
            v.key = i;
            return <Card key={i} data={v} />;
          })}
        </div>
      </div>
    </Layout>
  );
}

export default Reviews;
