import Layout from "./Layout";
import Ripples from "react-ripples";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <Layout onlyHeader={true}>
      <div className="ssbf">
        <h1>Ooops! 404</h1>
        <h2>The Page You'r Looking is Not Available</h2>
        <div className="d-flex justify-content-center">
          <Ripples className="bbtn_top bbtn_long mr-5 pl-0 pr-0">
            <Link to={`/share-text`}>
              <button className="bbtn_top bbtn_long">Share Text</button>
            </Link>
          </Ripples>
          <Ripples className="bbtn_top bbtn_long mr-5 pl-0 pr-0">
            <Link to={`/share-files`}>
              <button className="bbtn_top bbtn_long">Share Files</button>
            </Link>
          </Ripples>
        </div>
      </div>
    </Layout>
  );
}

export default PageNotFound;
