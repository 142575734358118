import Layout from "./Layout";
import Main from "../components/main";
function App() {
  return (
    <Layout>
      <Main />
    </Layout>
  );
}

export default App;
