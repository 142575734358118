import Layout from "./Layout";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  useEffect(() => {}, []);
  return (
    <Layout>
      <div className="imgbox-qrcode psr">
        <img
          className="psr"
          src="https://api.google4share.com/assets/qrcode.svg"
          alt="Scan Qr Code To Open Website On you'r mobile."
          title="Scan Qr Code To Open Website On you'r mobile."
        />
      </div>
      <div>
        <ul className="ul-conct">
          <li>
            <Link to="mailto:support@google4share.com">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960">
                <path d="M186 844q-22.775 0-38.387-15.612Q132 812.775 132 790V362q0-22.775 15.613-38.388Q163.225 308 186 308h588q22.775 0 38.388 15.612Q828 339.225 828 362v428q0 22.775-15.612 38.388Q796.775 844 774 844H186Zm294-281L154 344v446q0 14 9 23t23 9h588q14 0 23-9t9-23V344L480 563Zm0-28 309-205H172l308 205ZM154 344v-14 460q0 14 9 23t23 9h-32V344Z" />
              </svg>
              support@google4share.com
            </Link>
          </li>
        </ul>
      </div>
    </Layout>
  );
};
export default Contact;
