import Ripples from "react-ripples";
import { Link } from "react-router-dom";
import Location from "./Location";
import { useEffect, useState } from "react";
import axios from "axios";
import Label from "./label";
import Card from "./card";
import LocationChange from "./LocationChange";

const Files = () => {
  const [Tab, setTeb] = useState();
  const [Loading, setLoading] = useState(false);
  const [shareFile, setShareFile] = useState();
  const [isEror, setisError] = useState(false);
  const [cSelec, setcSelect] = useState(localStorage.getItem("sloc"));
  const [cCountry, setcCountry] = useState(localStorage.getItem("ccode"));
  const [sharedFiles, setSharedFiles] = useState(
    Window.ServerData?.csharedFiles
  );
  const uploadFile = (data) => {
    setLoading(true);
    axios
      .post(
        "https://api.google4share.com/file.php",
        { file: data, cloc: localStorage.getItem("sloc") },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => {
        setShareFile();
        if (data.success) {
          setSharedFiles(data.files);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading("error");
      });
  };
  const GetFiles = () => {
    setisError("loading");
    axios
      .get(
        "https://api.google4share.com/data.php?cloc=" +
          localStorage.getItem("sloc"),
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(({ data }) => {
        setisError(false);
        setSharedFiles(data?.files);
      })
      .catch(() => {
        setisError(true);
        console.log("Failed To Get Record Check Your Connection");
      });
  };
  const Points = [
    "Select the file and location from the top right corner .",
    `Currently you'r sharing on ${
      cSelec === "swn" ? "Selected Wifi Network" : cSelec
    }`,
    `You'r Currently Located in ${cCountry}`,
    "You Can Share Up To 20GB",
    "You'r File Will Be Auto Deleted After 3 Days",
  ];
  useEffect(() => {
    setTeb("file");
    GetFiles();
    LocationChange(setcCountry);
    if (window?.navigator?.connection) {
      window.navigator.connection.onchange = () => {
        LocationChange(setcCountry);
      };
    }
  }, []);
  return (
    <div>
      <Label setcSelect={setcSelect} cSelec={cSelec} />
      <div className="btns_flex_tp">
        <Ripples className="bbtn_top">
          <Link to="/">
            <button className="bbtn_top">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="25"
                viewBox="0 96 960 960"
                width="25"
              >
                <path d="M480 856 200 576l280-280 16 16-254 253h518v22H242l254 253-16 16Z" />
              </svg>
            </button>
          </Link>
        </Ripples>
        <div>
          <Ripples className="bbtn_top mr-5">
            <button className="bbtn_top" title="Select a file to upload from your device">
              <label htmlFor="select_file" className="select_file">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M469 830V587H226v-22h243V322h22v243h243v22H491v243h-22Z" />
                </svg>
                <input
                  type="file"
                  id="select_file"
                  className="bbtn_hide_inp"
                  onChange={(e) => {
                    setShareFile(e.target.files[0]);
                    uploadFile(e.target.files[0]);
                  }}
                />
              </label>
            </button>
          </Ripples>

          {Loading === true ? (
            <Ripples className="bbtn_top mr-5">
              <button className="bbtn_top" title="Sharing ...">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  className="rotate_svg"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M196 862v-22h134l-50-49q-48-47-71-99.5T186 580q0-90 50-165t134-111v23q-74 34-118 102.5T208 580q0 56 21 104t62 87l56 54V689h22v173H196Zm394-14v-23q75-34 118.5-102.5T752 572q0-51-21-100.5T670 382l-56-55v136h-22V290h173v22H631l49 50q48 47 71 101.5T774 572q0 90-50 165T590 848Z" />
                </svg>
              </button>
            </Ripples>
          ) : Loading === "error" ? (
            <Ripples
              className="bbtn_top mr-5"
              onClick={() => {
                uploadFile(shareFile);
              }}
            >
              <button
                className="bbtn_top error_ntb"
                title="Error While Sharing Check Your Internet Connection"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M479.982 748q8.518 0 13.268-4.732 4.75-4.733 4.75-13.25 0-8.518-4.732-13.268-4.733-4.75-13.25-4.75-8.518 0-13.268 4.732-4.75 4.733-4.75 13.25 0 8.518 4.732 13.268 4.733 4.75 13.25 4.75ZM470 626h22V382h-22v244Zm10.44 298q-72.908 0-135.732-27.391-62.825-27.392-110.341-74.848-47.515-47.457-74.941-110.276Q132 648.667 132 575.674q0-71.993 27.391-135.392 27.392-63.399 74.848-110.414 47.457-47.016 110.276-74.442Q407.333 228 480.326 228q71.993 0 135.392 27.391 63.399 27.392 110.414 74.348 47.016 46.957 74.442 110.435Q828 503.652 828 575.56q0 72.908-27.391 135.732-27.392 62.825-74.348 110.156-46.957 47.332-110.435 74.942Q552.348 924 480.44 924Zm.06-22Q616 902 711 807t95-231.5Q806 440 711.188 345 616.375 250 480 250q-136 0-231 94.812Q154 439.625 154 576q0 136 95 231t231.5 95Zm-.5-326Z" />
                </svg>
              </button>
            </Ripples>
          ) : (
            <Ripples className="bbtn_top mr-5">
              <button
                className="bbtn_top"
                title="Current File Is Shared .
Click To Get The Latest Update."
                onClick={() => {
                  GetFiles();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M411 918q-61-14-112-45t-87.5-76Q175 752 155 695.5T135 576q0-84 39-158.5T293 285H159v-22h172v172h-22V302q-77 52-114.5 124T157 576q0 115 69 203.5T411 895v23Zm12-198L294 591l16-16 113 113 227-227 16 16-243 243Zm206 170V718h22v133q77-53 114.5-125T803 576q0-115-69-203.5T549 257v-23q123 27 199.5 121T825 576q0 84-39 159T667 868h134v22H629Z" />
                </svg>
              </button>
            </Ripples>
          )}

          {Tab === "file" ? (
            <Ripples
              className="bbtn_top"
              onClick={() => {
                setTeb("location");
              }}
            >
              <button className="bbtn_top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="M521 922v-22q47-7 87.5-23.5T680 835l14 16q-37 30-80 46.5T521 922Zm234-131-16-15q26-34 42.5-74t22.5-85h22q-5 46-23.5 90T755 791Zm49-256q-6-45-22.5-85T739 376l17-14q33 47 46.5 82t23.5 91h-22ZM439 922q-131-17-218-115.5T134 576q0-133 87-231.5T439 230v22q-122 17-202.5 108.5T156 576q0 122 80.5 214T439 900v22Zm243-603q-37-27-77.5-44T524 252v-22q46 7 88 24.5t82 46.5l-12 18ZM480 728q-61-53-91.5-98.5T358 546q0-57 36.5-91t85.5-34q49 0 85.5 34t36.5 91q0 38-30.5 83.5T480 728Zm0-152q14 0 23.5-9.5T513 543q0-14-9.5-23.5T480 510q-14 0-23.5 9.5T447 543q0 14 9.5 23.5T480 576Z" />
                </svg>
              </button>
            </Ripples>
          ) : (
            <Ripples
              className="bbtn_top"
              onClick={() => {
                setTeb("file");
              }}
            >
              <button className="bbtn_top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="25"
                  viewBox="0 96 960 960"
                  width="25"
                >
                  <path d="m254 818-16-16 226-226-226-226 16-16 226 226 226-226 16 16-226 226 226 226-16 16-226-226-226 226Z" />
                </svg>
              </button>
            </Ripples>
          )}
        </div>
      </div>

      {Tab === "file" ? (
        <>
          {isEror === true ? (
            <div className="ucard_bg icard_bg_retry">
              <Ripples
                className="add_ntb"
                onClick={() => {
                  GetFiles();
                }}
              >
                <button className="add_ntb">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="35"
                    viewBox="0 96 960 960"
                    width="35"
                  >
                    <path d="M484.322 844q-111.547 0-189.934-78.341Q216 687.317 216 576.159 216 465 294.388 386.5 372.775 308 484 308q77 0 139 37.5T722 448V308h22v180H564v-22h142q-33-62-91-99t-131-37q-103.167 0-174.583 71.382Q238 472.765 238 575.882 238 679 309.525 750.5T484.372 822Q563 822 627 777q64-45 90-119h23q-26 84-96.707 135-70.706 51-158.971 51Z" />
                  </svg>
                </button>
              </Ripples>
            </div>
          ) : isEror === "loading" ? (
            <div className="ucard_bg icard_bg_retry" title="Click to retry">
              <div className="add_ntb">
                <div className="add_ntb">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="32"
                    className="rotate_svg"
                    viewBox="0 96 960 960"
                    width="32"
                  >
                    <path d="M524 924v-23q34-5 66.5-18.5T654 847l16 16q-32 24-69 39t-77 22Zm-83 0q-116-16-191.5-103T174 616q0-63 24-119.5t66-98q42-41.5 98-66T482 308h42l-95-95 17-15 121 121-121 122-17-16 95-95h-42q-120 0-203 83.5T196 616q0 108 69.5 188T441 903v21Zm286-119-16-16q22-29 35.5-62t19.5-69h22q-6 40-21.5 77.5T727 805Zm61-230h-22q-6-35-19.5-68T711 444l16-16q23 30 39 67t22 80Z" />
                  </svg>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="flex-gri">
                {sharedFiles.length < 1 ? (
                  <div className="nfshared">
                    <h3>No File Shared</h3>
                    {Points.map((e, i) => {
                      return (
                        <p key={i}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            height={15}
                            width={15}
                            stroke="currentColor"
                            aria-hidden="true"
                            className="w-3.5 h-3.5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                            ></path>
                          </svg>
                          <div className="point-para">{e}</div>
                        </p>
                      );
                    })}
                  </div>
                ) : (
                  sharedFiles.map((v, i) => {
                    return (
                      <Card
                        v={v}
                        key={i}
                        index={i}
                        sharedFiles={sharedFiles}
                        setSharedFiles={setSharedFiles}
                      />
                    );
                  })
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <Location
          setcSelect={setcSelect}
          cSelec={cSelec}
          cCountry={cCountry}
          update={GetFiles}
        />
      )}
    </div>
  );
};
export default Files;
