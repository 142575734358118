import { useState } from "react";
import Layout from "./Layout";
import Ripples from "react-ripples";
import axios from "axios";

function UploadReviews() {
  const [Loading, setLoading] = useState(false);
  const [Review, setReview] = useState({
    name: "",
    email: "",
    file: "",
  });
  const uploadReview = () => {
    setLoading(true);
    axios
      .post(
        "https://api.google4share.com/upload_review.php",
        { ...Review },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => {
        if (data.success) {
          alert(data?.message);
        } else {
          alert(data?.message);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading("error");
      });
  };
  return (
    <Layout onlyHeader={true}>
      <div className="ssbf">
        <h1>Create Review</h1>
        <h2>Speak for our excellence.</h2>
        <div className="uload_card">
          <svg
            fill="none"
            className="svg_stroke"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0"
              y="0"
              width="100%"
              height="100%"
              rx="44.5"
              stroke="white"
              strokeWidth="3"
              strokeDasharray="19 19"
            />
          </svg>
          <div className="forminp">
            <label>Your Name</label>
            <input
              type="text"
              placeholder="Your Name"
              onChange={(e) => {
                setReview({ ...Review, name: e.target.value });
              }}
              value={Review?.name}
            />
          </div>
          <div className="forminp">
            <label>Your Email</label>
            <input
              type="email"
              placeholder="Your Email"
              onChange={(e) => {
                setReview({ ...Review, email: e.target.value });
              }}
              value={Review?.email}
            />
          </div>
          <div className="forminp">
            <label>Your Video Review</label>
            <input
              type="file"
              accept="video/*"
              onChange={(e) => {
                setReview({ ...Review, file: e.target?.files[0] });
              }}
            />
          </div>
          <div className="d-flex justify-content-right">
            <Ripples className="bbtn_top bbtn_long mr-5 pl-0 pr-0">
              <button
                className="bbtn_top bbtn_long"
                title="Submit"
                onClick={() => {
                  uploadReview();
                }}
              >
                {Loading === true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="25"
                    className="rotate_svg"
                    viewBox="0 96 960 960"
                    width="25"
                  >
                    <path d="M196 862v-22h134l-50-49q-48-47-71-99.5T186 580q0-90 50-165t134-111v23q-74 34-118 102.5T208 580q0 56 21 104t62 87l56 54V689h22v173H196Zm394-14v-23q75-34 118.5-102.5T752 572q0-51-21-100.5T670 382l-56-55v136h-22V290h173v22H631l49 50q48 47 71 101.5T774 572q0 90-50 165T590 848Z" />
                  </svg>
                ) : Loading === "error" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="25"
                    viewBox="0 96 960 960"
                    width="25"
                  >
                    <path d="M479.982 748q8.518 0 13.268-4.732 4.75-4.733 4.75-13.25 0-8.518-4.732-13.268-4.733-4.75-13.25-4.75-8.518 0-13.268 4.732-4.75 4.733-4.75 13.25 0 8.518 4.732 13.268 4.733 4.75 13.25 4.75ZM470 626h22V382h-22v244Zm10.44 298q-72.908 0-135.732-27.391-62.825-27.392-110.341-74.848-47.515-47.457-74.941-110.276Q132 648.667 132 575.674q0-71.993 27.391-135.392 27.392-63.399 74.848-110.414 47.457-47.016 110.276-74.442Q407.333 228 480.326 228q71.993 0 135.392 27.391 63.399 27.392 110.414 74.348 47.016 46.957 74.442 110.435Q828 503.652 828 575.56q0 72.908-27.391 135.732-27.392 62.825-74.348 110.156-46.957 47.332-110.435 74.942Q552.348 924 480.44 924Zm.06-22Q616 902 711 807t95-231.5Q806 440 711.188 345 616.375 250 480 250q-136 0-231 94.812Q154 439.625 154 576q0 136 95 231t231.5 95Zm-.5-326Z" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="25"
                    viewBox="0 96 960 960"
                    width="25"
                  >
                    <path d="M411 918q-61-14-112-45t-87.5-76Q175 752 155 695.5T135 576q0-84 39-158.5T293 285H159v-22h172v172h-22V302q-77 52-114.5 124T157 576q0 115 69 203.5T411 895v23Zm12-198L294 591l16-16 113 113 227-227 16 16-243 243Zm206 170V718h22v133q77-53 114.5-125T803 576q0-115-69-203.5T549 257v-23q123 27 199.5 121T825 576q0 84-39 159T667 868h134v22H629Z" />
                  </svg>
                )}
                Submit
              </button>
            </Ripples>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default UploadReviews;
